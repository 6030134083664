<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页设置<span>&gt;</span> 轮播图管理<span>&gt;</span>
			修改轮播图
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/carousel/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-form
				:model="carousel"
				status-icon
				:rules="rules"
				ref="postForm"
				label-width="100px"
				style="width:400px;margin-left:20px;margin-top:20px;"
			>
				<el-form-item label="轮播图" prop="image_url">
					<img width="100%" :src="apiServer + carousel.image_url" />
				</el-form-item>
				<el-form-item label="跳转链接" prop="target_url">
					<el-input v-model="carousel.target_url" placeholder="跳转链接"></el-input>
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input v-model="carousel.title" placeholder="标题"></el-input>
				</el-form-item>
				<el-form-item label="内容" prop="text">
					<el-input type="textarea" v-model="carousel.text" placeholder="内容"></el-input>
				</el-form-item>
				<el-form-item label="权重" prop="weight">
					<el-input type="number" v-model.number="carousel.weight" placeholder="权重（数字）" @mousewheel.native.prevent></el-input>
				</el-form-item>
				<el-form-item label="新页面打开" prop="new_page">
					<el-switch v-model="carousel.new_page"> </el-switch>
				</el-form-item>
				<el-button type="primary" style="margin-left:100px;" @click="saveCarousel">保存</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
import { apiGetCarouselById, apiUpdateCarousel } from "@/api/admin/carousel.js";
import base from "@/base.js";

export default {
	data() {
		var checkEmpty = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("不能为空"));
			} else {
				callback();
			}
		};
		return {
			apiServer: base.apiServer,
			carousel: {
				id: null,
				image_url: "",
				target_url: "",
				title: "",
				text: "",
				weight: null,
				new_page: true
			},
			rules: {
				target_url: [{ validator: checkEmpty, trigger: "blur" }]
			}
		};
	},
	methods: {
		saveCarousel() {
			//console.log(this.carousel);
			let formData = new FormData();
			formData.append("id", this.carousel.id);
			formData.append("image_url", this.carousel.image_url);
			formData.append("target_url", this.carousel.target_url);
			formData.append("title", this.carousel.title);
			formData.append("text", this.carousel.text);
			formData.append("weight", this.carousel.weight);
			formData.append("new_page", this.carousel.new_page);
			/* for (let [a, b] of formData.entries()) {
				console.log(a + ":", b || '""');
			} */
			apiUpdateCarousel(this.$store.state.token, formData).then((response) => {
				if (response.data.code == 200) {
					this.$message({ message: "更新成功", type: "success" });
					//执行成功1秒后跳转页面
					setTimeout(function() {
						window.location.href = "/pc/admin/carousel/list";
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			});
		},
		// 根据ID获取轮播图
		async getCarouselById() {
			try {
				const response = await apiGetCarouselById(this.$store.state.token, this.$route.query.id);
				//console.log(response);
				if (response.data.code == 200) {
					this.carousel = response.data.data;
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.getCarouselById();
	}
};
</script>

<style lang="scss" scoped>
.upload-demo {
	width: 297px;
}
</style>
